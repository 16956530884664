import Axios from "axios";

var URL = "";
var URL_EXTERNAL = "";
const { REACT_APP_ENV } = process.env;
console.log(`ENV: ${REACT_APP_ENV}`)

// URL = "http://127.0.0.1:3000/";
URL = "https://v2vkr2idpl.execute-api.us-east-1.amazonaws.com/Stage/";

const API = {
    getAuth: function (source, email, password) {
        return Axios.get(
            `${URL}service-partner/login?username=${email}&password=${password}`,
            { cancelToken: source.token }
        );
    },
    getDistributers: function (source) {
        let auth = localStorage.getItem('token');
        return Axios.get(
            `${URL}service-partner/distributers`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        );
    },
    getQuotes: function (source) {
        let auth = localStorage.getItem('token');
        return Axios.get(
            `${URL}service-partner/quotes`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        );
    },
    postQuote: function (source, payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(`${URL}/service-partner/quote`,
            payload,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        );
    },
    postRecover: function (source, payload) {
        let auth = localStorage.getItem('token');
        return Axios.post(`${URL}/service-partner/recover-password`,
            payload,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        );
    },
    putPassword: function (source, payload) {
        let auth = localStorage.getItem('token');
        return Axios.put(`${URL}/service-partner/password`,
            payload,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        );
    },
    finishRegistration: function (source, payload) {
        let auth = localStorage.getItem('token');
        return Axios.put(`${URL}service-partner/registration`,
            payload,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        );
    },
    cancelToken: function () {
        const cancelToken = Axios.CancelToken;
        return cancelToken.source();
    },
};

export default API; 