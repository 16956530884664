import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../utils/ContextProvider";
import { NavLink } from "react-router-dom";
import './style.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Navigation(props) {

    const context = useContext(AppContext);
    return (
      <div className="flex justify-center px-5 bg-white shadow-sm max-md:flex-wrap">
      <div className="flex flex-1 gap-4 items-center my-auto max-md:flex-wrap">
          <Link to={'./home'} className="flex justify-center items-center self-stretch my-auto">
              <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4d35fe7ed05f8e3fcdd0aa751d7943e394755aff62927523a196baa6a37998f7?apiKey=c04fd794c2224c5194cf77e67977c0a4&&apiKey=c04fd794c2224c5194cf77e67977c0a4"
                  className="w-20 aspect-[4.35]"
              />
          </Link>
          <div className="shrink-0 self-stretch w-px h-6 bg-gray-300" />
          <div className="self-stretch my-auto text-xl font-light text-slate-800 max-md:max-w-full">
              Lead System
          </div>
      </div>
      {
        context.getLogin &&
        <Link to={'./quote'} className="flex justify-center items-center p-5 pointer text-black">
            <p>Solicitar Orçamento</p>
        </Link>
      }
      {
        context.getLogin &&
        <Link to={'./quotes'} className="flex justify-center items-center p-5 pointer text-black">
            <p>Minhas Solicitações</p>
        </Link>
      }

      <div className="flex justify-center items-center p-5 pointer" onClick={() => { context.logOut() }}>
          <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bee595cb35ae06082a1e346395c70c043ad49ff656612892a40ea557d410a22?apiKey=c04fd794c2224c5194cf77e67977c0a4&&apiKey=c04fd794c2224c5194cf77e67977c0a4"
              className="w-5 aspect-square"
          />
      </div>
  </div>
    )
}

export default Navigation;