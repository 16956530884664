import { useState, createContext, useEffect } from 'react';
import { useHistory } from 'react-router';


export const AppContext = createContext();

export const ContextProvider = (props) => {

    const [login, setLogin] = useState(localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem("email") && localStorage.getItem("role"));
    const [auth, setAuth] = useState();
    const [email, setEmail] = useState();
    const [role, setRole] = useState();
    const history = useHistory();

    useEffect(() => {
        let token = localStorage.getItem('token');
        let user = localStorage.getItem('user');
        let email = localStorage.getItem("email");
        let role = localStorage.getItem("role");
        if (token && user && email && role) {
            setLogin(true);
            setAuth(token);
            setEmail(email);
            setRole(role);
        }
    }, []);

    const logOut = () => {
        localStorage.removeItem('email');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        setLogin(false);
        history.push('/login');
    }

    const logIn = (email, token, user, role) => {
        localStorage.setItem('email', email);
        localStorage.setItem('token', token);
        localStorage.setItem('user', user);
        localStorage.setItem('role', role);
        setLogin(true);
        setAuth(token);
        setEmail(email);
        setRole(role);
    }

    return (
        <AppContext.Provider value={
            {
                getLogin: login,
                logIn: (email, token, user, role) => logIn(email, token, user, role),
                logOut: logOut,
                getAuth: auth,
                getEmail: email,
                getRole: role,
                setAuth: setAuth
            }
        }>
            {props && props.children}
        </AppContext.Provider>
    )
}

export default ContextProvider;