import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.css';
import { useLocation } from 'react-router';
import { AppContext } from "../../utils/ContextProvider";
import InputMask from 'react-input-mask';
import API from '../../utils/API';
import Swal from 'sweetalert2';

function Registration() {
    const { state } = useLocation();
    // const navigate = useNavigate()
    const context = useContext(AppContext);
    const [loginLabel, setLoginLabel] = useState("Entrar") 
    const [comapanyName, setCompantName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [address, setAddress] = useState("")
    const [stage, setStage] = useState(0);
    const [password, setPassword] = useState("")
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [mask, setMask] = useState("+55 (99) 99999-9999")
    const [passwordStrength, setPasswordStrength] = useState(0)
    const [authCode, setAuthCode] = useState("")
    const [error, setError] = useState({ email: false, comapanyName: false, phoneNumber: false, address: false, password: false })
    const [submittionDisabled, setIsSubtionDisabled] = useState(true)
    const [submittionDisabled1, setIsSubtionDisabled1] = useState(false)
    const history = useHistory();
    const [form, setForm] = useState({
        comapanyName: "",
        phoneNumber: "",
        address: "",
        email: "",
        password: "",
        termsOfUse: false,
        policies: false,
        notifications: false
    })


    const handlerSubmit = () => {
        Swal.fire({
            title: 'Finalizando Cadastro...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        
        var isValid = validationForm()
        console.log(form)
        if (!isValid) {
            Swal.close()
            return
        }
        let cancelToken = API.cancelToken()
        API.finishRegistration( cancelToken, form).then((res) => {
            console.log(res)
            if (res.data.message === "OK") {
                window.location.reload()
            }
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao finalizar o cadastro!'
            })
        })
        return
    }


    const evaluatePasswordStrength = (password) => {
        // Criteria checks as before
        if (password.length > 40) {
            return 6
        }
        if (password.length < 5) {
            return 1
        }
        const metCriteria = [password.length >= 8, /\d/.test(password), /[A-Z]/.test(password), /[a-z]/.test(password), /[!@#$%^&*(),.?":{}|<>]/.test(password)].filter(Boolean).length;
        return metCriteria; // Returns a number between 0 and 5
    };

    const handleChange = (event) => {
        let { name, value } = event.target;

        let newError = { ...error }
        let newForm = { ...form }
        if (name == "policies" || name == "termsOfUse" || name == "notifications") {
            newForm[name] = !newForm[name]
        }

        if (name == "email") {
            if (!validateEmail(value)) {
                setError({ ...error, email: "E-mail inválido" })
                setIsSubtionDisabled1(false)
                newForm['email'] = value
            } else {
                newForm['email'] = value
                setIsSubtionDisabled1(true)
                setError({ ...error, email: null })
            }
        }

        if (name == "address") {
            newForm['address'] = value
            newError.address = false
        }
        
        if (name == "password") {
            if (value === "") {
                setPasswordStrength(0);
                return;
            }
            setPasswordStrength(evaluatePasswordStrength(value));
            newForm['password'] = value
            newError.password = false
        }
        if (name === "phone_number") {
            newForm['phoneNumber'] = value
            newError.phoneNumber = false
        }

        setError(newError)
        setForm(newForm)

    }


    const handlePasswordChange = (event) => {
        const { value } = event.target;
        setPassword(value);
        if (value === "") {
            setPasswordStrength(0);
            return;
        }
        setPasswordStrength(evaluatePasswordStrength(value));
    };

    const moveStage = (newStage) => {
        Swal.close()
        setStage(newStage)
    }


    const validationForm = () => {
        var auxError = { email: false, comapanyName: false, phoneNumber: false, address: false, password: false }
        var isValid = true
        if (!form.comapanyName) {
            auxError.comapanyName = "Campo Obrigatório"
            isValid = false
        }
        if (!validatePhone(form.phoneNumber)) {
            auxError.phoneNumber = "Telefone inválido"
            isValid = false
        }
        if (!form.address) {
            auxError.address = "Campo Obrigatório"
            isValid = false
        } if (!(passwordStrength === 4 || passwordStrength === 5 || passwordStrength === 3)) {
            auxError.password = "Senha Fraca"
            isValid = false
        }
        if (passwordStrength === 6) {
            auxError.password = "Senha deve conter menos de 40 caracteres."
            isValid = false
        }
        if(!form.termsOfUse){
            isValid = false
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Você precisa aceitar os termos de uso!'
            })
        }
        if(!form.policies){
            isValid = false
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Você precisa aceitar as políticas de troca, garantia e privacidade!'
            })
        }
        setError(auxError)
        return isValid
    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }

    const validatePhone = (phone) => {
        phone = `${phone}`.replace(/\D/g, '');
        if (phone.length === 13 || phone.length === 12) {
            return true
        } else {
            return false
        }
    };

    const handleSubmit = () => {
        // setLoginLabel("Efetuando Login...")
        if(stage == 0){
            Swal.fire({
                title: 'Validando acesso...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })
        }
        if(stage == 3){
            Swal.fire({
                title: 'Efetuando log in...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })
        }
        API.getAuth({ token: null }, form.email, encodeURIComponent(form.password)).then((res) => {   
            console.log(res)
            if(res.data.status === "REGISTRATION_PENDING"){
                moveStage(1)
                let newForm = { ...form }
                newForm.email = res.data.info.email
                newForm.comapanyName = res.data.info.name
                newForm.cnpj = res.data.info.cnpj
                setForm(newForm)

            }
            if(res.data.status === "EXSISTS"){
                console.log("User already exists")
                setIsSubtionDisabled1(false)
                moveStage(3)
                return
            }
            if(res.data.token != undefined){
                Swal.close()
                context.logIn(form.email, res.data.token, res.data.email, "1")
                history.push('/home');
            }
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuário ou senha inválidos!'
            })
            setLoginLabel("Entrar")
        })
    }

    const handleUpdatePhone = (event) => {
        const { value } = event.target;
        var phone = `${value}`.replace(/\D/g, '');
        setPhoneNumber(phone)
        if (!validatePhone(phone)) {
            // setError(true)
        } else {
            // setError(false)
        }
    }

    return (
        <div className="flex items-start justify-center gap-3 py-6 self-stretch w-full bg-[#EBF0F0] font-skf">
            {
                stage == 0 &&
                <div className="flex justify-center items-center px-16 py-20 w-full text-base font-medium leading-5 max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col px-10 pt-10 pb-12 mt-4 mb-52 max-w-full bg-white rounded border border-solid border-neutral-300 w-[480px] max-md:px-5 max-md:mb-10">
                        <div className="text-3xl text-slate-800">Boas-vindas!</div>
                        <div className="mt-2 leading-6 text-slate-800">
                            Valide seu acesso para{" "}
                            <span className="font-bold">Lead System</span>
                        </div>
                        <div className="mt-8 text-sm leading-5 text-gray-500">E-mail</div>
                        <input className="px-4 py-3.5 mt-2 text-black whitespace-nowrap bg-white rounded-lg border border-solid border-neutral-300" placeholder="e-mail@exemplo.com.br" name="email" onChange={handleChange}>
                        </input>
                        {error.email && <div className='flex items-center gap-2 self-stretch mt-2'>
                            <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                            <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.email}</span>
                        </div>}
                        <button className={`px-6 py-5 mt-8 text-lg leading-5 text-center text-white whitespace-nowrap rounded-lg ${submittionDisabled1 ? 'bg-[#0F58D6]' : 'bg-slate-500'} max-md:px-5`} onClick={handleSubmit} >
                            Prosseguir
                        </button>
                    </div>
                </div>
            }

            {
                stage == 1 &&
                <div className='flex flex-col w-auto px-10 pt-10 pb-16 items-center gap-8 shrink-0 bg-[#FFF] border rounded border-[#C7CAD0]'>
                    <p className='text-3xl font-medium leading-9 self-stretch text-[#273342]'>
                        Complete seu cadastro
                    </p>
                    <div className='flex flex-col items-center gap-4 self-stretch'>
                        <div className='flex flex-col self-stretch gap-2'>
                            <p className="text-sm font-medium leading-5 self-stretch text-[#676F7C]">Nome da empresa</p>
                            <input
                                className={`flex text-black h-11 w-full px-4 py-0 self-stretch bg-[#FFF] placeholder-[#AAAFB8] rounded-lg border ${error.comapanyName ? "border-[#C00]" : "border-[#C7CAD0]"}`}
                                value={form && form.comapanyName}
                                onChange={handleChange}
                                placeholder=""
                                type="text"
                                disabled={true}
                            />
                            {error.comapanyName && <div className='flex items-center gap-2 self-stretch'>
                                <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                                <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.comapanyName}</span>
                            </div>}
                        </div>
                        <div className='flex flex-col self-stretch gap-2'>
                            <p className="text-sm font-medium leading-5 self-stretch text-[#676F7C]">Endereço da sede</p>
                            <input
                                className={`flex text-black h-11 w-full px-4 py-0 self-stretch bg-[#FFF] placeholder-[#AAAFB8] rounded-lg border ${error.address ? "border-[#C00]" : "border-[#C7CAD0]"}`}
                                value={form.address && form.address}
                                onChange={handleChange}
                                placeholder=""
                                type="text"
                                name="address"
                                disabled={false}
                            />
                            {error.address && <div className='flex items-center gap-2 self-stretch'>
                                <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                                <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.address}</span>
                            </div>}
                        </div>
                        <div className='flex flex-col self-stretch gap-2'>
                            <p className="text-sm font-medium leading-5 self-stretch text-[#676F7C] text-black">Telefone/celular</p>
                            <InputMask
                                mask={mask}
                                className={`flex text-black h-11 w-full px-4 py-0 self-stretch bg-[#FFF] placeholder-[#ffffff] text-black rounded-lg border ${error.phoneNumber ? "border-[#C00]" : "border-[#C7CAD0]"}`}
                                onBlur={e => {
                                    if (e.target.value.replace("_", "").length === 18) {
                                        setMask("+55 (99) 9999-9999");
                                    }
                                }}
                                onFocusCapture={e => {
                                    if (e.target.value.replace("_", "").length === 18) {
                                        setMask("+55 (99) 99999-9999");
                                    }
                                }}
                                type="text"
                                name="phone_number"
                                placeholder="+55 (__) _____-____"
                                onChange={handleChange}
                                value={form.phoneNumber} />
                            {error.phoneNumber && <div className='flex items-center gap-2 self-stretch'>
                                <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                                <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.phoneNumber}</span>
                            </div>}
                        </div>
                        <div className='flex flex-col self-stretch gap-2'>
                            <p className="text-sm font-medium leading-5 self-stretch text-[#676F7C]">E-mail</p>
                            <input
                                className={`flex text-black h-11 w-full px-4 py-0 self-stretch bg-[#FFF] placeholder-[#AAAFB8] rounded-lg border ${error.email ? "border-[#C00]" : "border-[#C7CAD0]"}`}
                                value={form.email}
                                placeholder="Digite o seu e-mail"
                                type="text"
                                disabled={true}
                            />
                            {error.email && <div className='flex items-center gap-2 self-stretch'>
                                <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                                <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.email}</span>
                            </div>}
                        </div>
                        <div className='flex flex-col self-stretch gap-2'>
                            <p className="text-sm font-medium leading-5 self-stretch text-[#676F7C]">Senha</p>
                            <div class="relative">
                                <input
                                    className={`flex text-black h-11 w-full px-4 justify-end items-center py-0 self-stretch bg-[#FFF] placeholder-[#AAAFB8] rounded-lg border ${error.password ? "border-[#C00]" : "border-[#C7CAD0]"}`}
                                    name="password"
                                    value={form.password}
                                    onChange={handleChange}
                                    placeholder="Digite a senha"
                                    type={isPasswordVisible ? "text" : "password"}
                                    disabled={false}
                                    icon={<i class="fa-regular fa-eye-slash" />} />
                                <button
                                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                    onClick={togglePasswordVisibility}
                                >
                                    {isPasswordVisible ? (
                                        <img alt='eyeopen' src={require('../../assets/images/eye-open.png')} />
                                    ) :
                                        <img alt='eyeoff' src={require('../../assets/images/eye-off.png')} />
                                    }
                                </button>
                            </div>
                            {error.password && <div className='flex items-center gap-2 self-stretch'>
                                <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                                <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.password}</span>
                            </div>}
                        </div>
                        <div className='flex py-6 flex-col items-center gap-4 self-stretch'>
                            <div className='flex pr-4 items-center gap-2 self-stretch '>
                                <label class="relative flex items-center p-0 rounded-full cursor-pointer" htmlFor="blue">
                                    <input type="checkbox" name="termsOfUse"
                                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                        id="blue" checked={form.termsOfUse} onChange={handleChange} />
                                    <span
                                        class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                            stroke="currentColor" stroke-width="1">
                                            <path fill-rule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </span>
                                </label>
                                <p className='text-base text-[#273342]'>
                                    Confirmo que li e aceito os <b>termos de uso</b>
                                </p>
                            </div>
                            <div className='flex pr-4 items-center gap-2 self-stretch '>
                                <label class="relative flex items-center p-0 rounded-full cursor-pointer" htmlFor="blue">
                                    <input type="checkbox" name="policies"
                                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                        id="blue" checked={form.policies} onChange={handleChange} />
                                    <span
                                        class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                            stroke="currentColor" stroke-width="1">
                                            <path fill-rule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </span>
                                </label>
                                <p className='text-base text-[#273342]'>
                                    Confirmo que li e aceito as <b>políticas de troca, garantia e privacidade</b>
                                </p>
                            </div>
                            <div className='flex pr-4 items-center gap-2 self-stretch'>
                                <label class="relative flex items-center p-0 rounded-full cursor-pointer" htmlFor="blue">
                                    <input type="checkbox" name="notifications"
                                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                        id="blue" checked={form.notifications} onChange={handleChange} />
                                    <span
                                        class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                            stroke="currentColor" stroke-width="1">
                                            <path fill-rule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </span>
                                </label>
                                <p className='text-base text-[#273342]'>
                                    Quero receber <b>notificações do AZPartes</b> por e-mail
                                </p>
                                <p className='text-[#AAAFB8]'>(opcional)</p>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center gap-4 self-stretch'>
                            <button className={`px-6 py-5 mt-14 text-lg font-medium leading-5 text-center text-white rounded-lg  max-md:px-5 max-md:mt-10 max-md:max-w-full self-stretch bg-[#0F58D6]`} onClick={handlerSubmit}>
                                Finalizar Cadastro
                            </button>
                            <div className={`flex px-5 h-14 items-center pt-[18px] pb-[19px] justify-center self-stretch rounded-lg cursor-pointer `} >
                                <p className="text-lg font-medium text-center leading-5 self-stretch text-[#0F58D6]" onClick={() => window.location.reload()}>Cancelar</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                stage == 3 &&
                <div className="flex justify-center items-center px-16 py-20 w-full text-base font-medium leading-5 max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col px-10 pt-10 pb-12 mt-4 mb-52 max-w-full bg-white rounded border border-solid border-neutral-300 w-[480px] max-md:px-5 max-md:mb-10">
                        <div className="text-3xl text-slate-800">Boas-vindas!</div>
                        <div className="mt-2 leading-6 text-slate-800">
                            Valide seu acesso para{" "}
                            <span className="font-bold">Lead System</span>
                        </div>
                        <div className="mt-8 text-sm leading-5 text-gray-500">E-mail</div>
                        <input className="px-4 py-3.5 mt-2 text-black whitespace-nowrap bg-white rounded-lg border border-solid border-neutral-300" placeholder="e-mail@exemplo.com.br" name="email" onChange={handleChange} value={form && form.email}>
                        </input>
                        {error.email && <div className='flex items-center gap-2 self-stretch mt-2'>
                            <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                            <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.email}</span>
                        </div>}

                        <div className='flex flex-col self-stretch gap-2 mt-2'>
                            <p className="text-sm font-medium leading-5 self-stretch text-[#676F7C]">Senha</p>
                            <div class="relative">
                                <input
                                    className={`flex text-black h-11 w-full px-4 justify-end items-center py-0 self-stretch bg-[#FFF] placeholder-[#AAAFB8] rounded-lg border ${error.password ? "border-[#C00]" : "border-[#C7CAD0]"}`}
                                    value={form && form.password}
                                    onChange={handleChange}
                                    placeholder="Digite a senha"
                                    type={isPasswordVisible ? "text" : "password"}
                                    disabled={false}
                                    name='password'
                                    icon={<i class="fa-regular fa-eye-slash" />} />
                                <button
                                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                    onClick={togglePasswordVisibility}
                                >
                                    {isPasswordVisible ? (
                                        <img alt='eyeopen' src={require('../../assets/images/eye-open.png')} />
                                    ) :
                                        <img alt='eyeoff' src={require('../../assets/images/eye-off.png')} />
                                    }
                                </button>
                            </div>
                            {error.password && <div className='flex items-center gap-2 self-stretch'>
                                <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                                <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.password}</span>
                            </div>}

                            <Link to={`/password-recover?e=${form.email}`} className={`flex px-5 h-14 items-center pt-[18px] pb-[19px] justify-center self-stretch rounded-lg cursor-pointer `}>
                                <p className="text-sm font-medium text-center leading-5 self-stretch text-[#0F58D6]">Esqueci minha senha</p>
                            </Link>

                            {/* <div className='flex flex-col self-stretch gap-2'>
                                <p className="text-sm font-medium leading-5 self-stretch text-[#676F7C]">Digite o código autenticador enviado ao seu e-mail:</p>
                                <input className={`flex h-11 w-full px-4 py-0 self-stretch bg-[#FFF] placeholder-[#AAAFB8] rounded-lg border border-[#C7CAD0]`}
                                    value={authCode && authCode}
                                    onChange={(e) => setAuthCode(e.target.value)}
                                    placeholder="Digite o código"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className={`flex px-5 h-14 items-center pt-[18px] pb-[19px] justify-center self-stretch rounded-lg cursor-pointer `} >
                                <p className="text-sm font-medium text-center leading-5 self-stretch text-[#0F58D6]">Reenviar código por e-mail</p>
                            </div> */}
                        </div>


                        <button className={`px-6 py-5 mt-8 text-lg leading-5 text-center text-white whitespace-nowrap rounded-lg bg-[#0F58D6] max-md:px-5`} onClick={handleSubmit} disabled={loginLabel === "Entrar" ? false : true } >
                            {loginLabel}
                        </button>
                    </div>
                </div>
            }
        </div >
    )
}

export default Registration;