import React from "react";

const Footer = () => {
  return (
    <div className="flex flex-col justify-center px-16 py-7 w-full text-xl font-light leading-7 bg-[#264357] text-slate-800 max-md:px-5 max-md:max-w-full fixed bottom-0 right-0 left-0">      
      <div className="flex gap-2.5 justify-center max-md:flex-wrap ">
        <div className="flex flex-1 gap-4 items-center max-md:flex-wrap">
          <div className="flex justify-center items-center self-stretch my-auto">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/35a8137090bfe6d28ac0575542c7db0708743285973595a38e05563ac07ea71e?apiKey=c04fd794c2224c5194cf77e67977c0a4&&apiKey=c04fd794c2224c5194cf77e67977c0a4"
              className="w-20 aspect-[4.35]"
            />
          </div>
          <div className="shrink-0 self-stretch w-px h-6 bg-white" />
          <div className="self-stretch my-auto text-xl font-light leading-7 text-white max-md:max-w-full">
            Lead System
          </div>
        </div>
        <div className="self-start text-base font-light leading-5 text-white">
          Powered by AZPartes.com
        </div>
      </div>
    </div>

  );
};

export default Footer; 