import React, { useState } from "react";
import './App.css';
import GetQuotes from "./pages/GetQuotes";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContextProvider from "./utils/ContextProvider";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Quote from "./pages/Quote";
import PasswordRecover from "./pages/PasswordRecover";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const [style, setStyle] = useState({ display: "visible" });
  function isClicked() {
    if (style.display === "none") {
      setStyle({ visibility: "visible" });
    } else {
      setStyle({ display: "none" });
    }
  }
  return (
    <Router>
      <ContextProvider>
        <div className="flex-row-nowrap primary-bg-color">
          <div className={`min-h-screen  w-full bg-[#EBF0F0]`}>
            <Navigation/>
            <Switch>
              <Route path="/quotes" component={GetQuotes} />
              <Route path="/login" component={Login} />
              <Route path="/password-recover" component={PasswordRecover} />
              <Route path="/password-reset" component={ResetPassword} />
              <Route path="/home" component={Home} />
              <Route path="/quote" component={Quote} />
              <Route path="*" component={Home} />
            </Switch>
            <Footer />
          </div>
        </div>
      </ContextProvider>
    </Router>
  );
}

export default App;
