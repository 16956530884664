import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import API from "../../utils/API";
import Swal from "sweetalert2";
import './style.css';
import { AppContext } from "../../utils/ContextProvider";

function GetQuotes() {
    const [quotes, setQuotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredQuotes, setFilteredQuotes] = useState([]);
    const history = useHistory();
    const context = useContext(AppContext);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const quote_id = query.get("quote_id");

    function transformDatetime(inputDatetimeStr) {
        // Parse the input datetime string into a Date object
        const dateObject = new Date(inputDatetimeStr);
    
        // Extract date components
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = dateObject.getFullYear();
    
        // Extract time components
        const hours = String(dateObject.getHours()).padStart(2, '0');
        const minutes = String(dateObject.getMinutes()).padStart(2, '0');
        const seconds = String(dateObject.getSeconds()).padStart(2, '0');
    
        // Format the datetime string as DD/MM/YYYY HH:MM:SS
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {

        if (!context.getLogin) {
            history.push('/login');
            return;
        }

        if(quote_id != undefined){
            Swal.fire({
                title: "Solicitação realizada com sucesso!",
                text: `Seu número de ID é #${quote_id}. Consulte nas suas solicitações.`,
                imageUrl: require("../../assets/images/good.png"),
                imageWidth: 230,
                imageHeight: 140,
                imageAlt: "Custom image",
                confirmButtonText: "OK",
                confirmButtonColor: "#0F58D6",
              }).then((result) => {
                if (result.isConfirmed && quotes.length === 0) {
                    Swal.fire({
                        title: 'Carregando Cotações...',
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        willOpen: () => {
                            Swal.showLoading();
                        }
                    });
                }
              })
        }else{
            Swal.fire({
                title: 'Carregando Cotações...',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            });
        }
        
        const source = API.cancelToken();
        API.getQuotes(source).then((response) => {
            setQuotes(response.data);
            setFilteredQuotes(response.data);
            Swal.close();
        }).catch((error) => {
            console.log(error);
            Swal.fire({
                title: 'Failed to load quotes',
                text: 'Please contact IT support.',
                icon: 'error',
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false
            });
        });
        return () => {
            source.cancel("Request canceled");
            Swal.close();
        }

        
    }, []);

    useEffect(() => {
        if (!searchTerm) {
            setFilteredQuotes(quotes);
        } else {
            setFilteredQuotes(quotes.filter(quote =>
                Object.values(quote).some(value =>
                    String(value).toLowerCase().includes(searchTerm.toLowerCase())
                )
            ));
        }
    }, [searchTerm, quotes]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="flex flex-col bg-white">
            <div className="flex flex-col mt-24 ml-16 max-w-full w-[855px] max-md:mt-10">
                <div className="text-5xl leading-[51.98px] text-slate-800 max-md:max-w-full max-md:text-4xl">
                    Minhas Solicitações
                </div>
                <input
                    type="text"
                    className="mt-4 p-2 border border-gray-300 rounded text-black"
                    placeholder="Pesquisar..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="flex flex-col px-16 py-12 w-full max-md:px-5 max-md:max-w-full">
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-200 justify-center items-center p-4 text-sm font-bold leading-5 bg-gray-200 max-md:px-5 grow justify-center text-base font-medium leading-5 text-slate-800">
                            <tr>
                                <th className="justify-center items-center p-4 text-sm font-bold leading-5 bg-gray-200 max-md:px-5">ID</th>
                                <th className="justify-center items-center p-4 text-sm font-bold leading-5 bg-gray-200 max-md:px-5">Data</th>
                                <th className="justify-center items-center p-4 text-sm font-bold leading-5 bg-gray-200 max-md:px-5">Produtos Solicitados</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-black justify-center items-center">
                            {filteredQuotes && filteredQuotes.length > 0 && filteredQuotes.map((company, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-4 whitespace-nowrap text-center">{company.service_partner_quote_id}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-center">{transformDatetime(company.created_at)}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-center">{company.products}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default GetQuotes;