import React, { useEffect, useState, useContext } from 'react';
import './style.css';
import SwitchToggle from '../../components/SwitchToggle';
import Select from 'react-select';
import API from '../../utils/API';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import { AppContext } from "../../utils/ContextProvider";

function Quote() {
    const history = useHistory();
    const context = useContext(AppContext);
    const [products, setProducts] = useState([
        {
            product: "",
            quantity: 1,
            distributors: [],
            sendToAll: false
        }
    ]);

    const [distributers, setDistributers] = useState([])
    const [productsList, setProductsList] = useState([])

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }

        API.getDistributers(API.cancelToken()).then((response) => {
            console.log(response);
            setDistributers(response.data);
            // setProductsList(response.data.products);
        })
    }, []);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedProducts = [...products];
        updatedProducts[index][name] = value;
        setProducts(updatedProducts);
    };

    const handleDistributorChange = (index, selectedOptions) => {
        const updatedProducts = [...products];
        updatedProducts[index].distributors = selectedOptions;
        setProducts(updatedProducts);
    };

    const increaseProduct = () => {
        setProducts([...products, { product: "", quantity: 1, distributors: [], sendToAll: false }]);
    };

    const handleToggleChange = (index) => {
        const updatedProducts = [...products];
        updatedProducts[index].sendToAll = !updatedProducts[index].sendToAll;
        if (updatedProducts[index].sendToAll) {
            // When sendToAll is ON, disable multi-select and set "Todos os distribuidores"
            updatedProducts[index].distributors = [{ value: 'todos', label: 'Todos os distribuidores' }];
        } else {
            // When sendToAll is OFF, clear the distributors selection
            updatedProducts[index].distributors = [];
        }
        setProducts(updatedProducts);
    };

    const handleSubmit = () => {


        Swal.fire({
            title: 'Gerando Solicitação...',
            text: 'Por favor aguarde...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            // didOpen: () => {
            //     Swal.showLoading();
            // },
            // didClose: () => {
            //     Swal.hideLoading();
            // }
        });

        // Extract the selected distributor values
        const processedProducts = products.map(product => ({
            ...product,
            distributors: product.distributors.map(option => option.value)
        }));

        // Validation
        for (let product of processedProducts) {
            if (!product.product.trim()) {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Product',
                    text: 'Please enter a valid product name for all items.',
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0F58D6',
                });
                return;
            }
            if (product.quantity < 1) {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Quantity',
                    text: 'Please enter a quantity greater than 0 for all items.',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0F58D6',
                    showConfirmButton: true,
                });
                return;
            }
            if (product.distributors.length === 0 && !product.sendToAll) {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'No Distributors Selected',
                    text: 'Please select at least one distributor or toggle "Send to All" for each product.',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0F58D6',
                    showConfirmButton: true,
                });
                return;
            }
        }

        // If validation passes, proceed with form submission
        console.log("Submitted Products:", processedProducts);

        API.postQuote(API.cancelToken(), processedProducts).then((response) => {
            console.log(response);
            Swal.close();
            Swal.fire({
                icon: 'success',
                title: 'Soliciatação enviada',
                text: 'Sua solicitação foi enviada com sucesso!',
                showConfirmButton: false,
                timer: 2000
            });
            history.push(`/quotes?quote_id=${response.data.service_partner_quote_id}`);
        }).catch((error) => {
            console.log(error);
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Falha ao enviar solicitação. Entre em contato com o suporte.',
                showConfirmButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#0F58D6',
            });
        }
        );
    };

    return (
        <div className="flex flex-col bg-neutral-100">
            <div className="flex flex-col items-start px-16 pt-14 pb-8 w-full text-slate-800 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-1 text-sm font-medium leading-5">
                    <div className="text-blue-700">Home</div>
                    {/* <div className="py-1 whitespace-nowrap">/</div> */}
                    <div>/ Solicitar orçamento</div>
                </div>
                <div className="mt-8 text-5xl leading-[51.98px] max-md:text-4xl">
                    Solicitar orçamento
                </div>
                <div className="mt-2.5 text-base leading-6">
                    Solicite quantos orçamentos quiser
                </div>
            </div>
            <div className="flex flex-col px-16 pb-20 w-full max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col px-10 pt-10 pb-12 bg-white rounded border border-solid border-neutral-300 max-md:px-5 max-md:max-w-full mb-10">
                    {products.map((product, index) => (
                        <div key={index} className={`${index > 0 ? 'mt-10' : ''} max-md:max-w-full`}>
                            <div className="flex gap-5 max-md:flex-col">
                                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                                        <div className="flex gap-4 max-md:flex-wrap">
                                            <div className="flex flex-col max-md:max-w-full">
                                                <div className="text-sm font-medium leading-5 text-gray-500 max-md:max-w-full">
                                                    Produto desejado
                                                </div>
                                                <div className="flex flex-col justify-center px-4 mt-2 text-base leading-5 bg-white rounded-lg border border-solid border-neutral-300 max-md:max-w-full">
                                                    <div className="flex gap-2 max-md:flex-wrap">
                                                        <input
                                                            className="flex-1 my-auto py-3 text-black"
                                                            placeholder="Digite o produto..."
                                                            name="product"
                                                            value={product.product}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col whitespace-nowrap">
                                                <div className="text-sm font-medium leading-5 text-gray-500">
                                                    Quantidade
                                                </div>
                                                <input
                                                    className="px-4 py-3 mt-2 text-black leading-5 w-[100px] bg-white rounded-lg border border-solid border-neutral-300"
                                                    type="number"
                                                    name="quantity"
                                                    value={product.quantity}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                                        <div className="text-sm font-medium leading-5 text-gray-500 max-md:max-w-full">
                                            Distribuidor desejado
                                        </div>
                                        <div className="flex flex-col justify-center mt-2 text-base leading-5 text-gray-400 bg-white max-md:max-w-full">
                                            <div className="flex gap-2 max-md:flex-wrap">
                                            <Select
                                                isMulti
                                                options={distributers}
                                                value={product.distributors}
                                                isDisabled={product.sendToAll}
                                                onChange={(selectedOptions) => handleDistributorChange(index, selectedOptions)}
                                                className="w-full"
                                                placeholder={product.sendToAll ? "Todos os distribuidores" : "Buscar distribuidor..."}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="flex gap-2 mt-2 max-md:flex-wrap max-md:max-w-full">
                                            <SwitchToggle
                                                isChecked={product.sendToAll}
                                                onChange={() => handleToggleChange(index)}
                                            />
                                            <div className="self-start text-base text-slate-800 max-md:max-w-full">
                                                Enviar para todos distribuidores listados
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div
                        className="flex gap-1 justify-center self-end px-4 py-3 mt-11 text-base font-medium leading-5 text-center text-blue-700 rounded-lg max-md:mt-10 pointer"
                        onClick={increaseProduct}
                    >
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e1f90f33700ed38cd73f9e51eea2416be9c594d02c6a485234254fe20aabee0?apiKey=c04fd794c2224c5194cf77e67977c0a4&&apiKey=c04fd794c2224c5194cf77e67977c0a4"
                            className="shrink-0 w-5 aspect-square"
                        />
                        <div>Adicionar outro produto</div>
                    </div>
                </div>
                <div className="flex gap-4 items-start pt-2 mb-36 text-lg font-medium leading-5 text-center max-md:flex-wrap max-md:pl-5 max-md:mb-10">
                    <Link to={"./home"} className="px-6 pt-5 pb-5 text-blue-700 whitespace-nowrap rounded-lg border border-blue-700 border-solid max-md:px-5">
                        CANCELAR
                    </Link>
                    <div
                        className="px-6 pt-5 pb-5 text-white rounded-lg bg-slate-500 max-md:px-5 pointer"
                        onClick={handleSubmit}
                    >
                        ENVIAR SOLICITAÇÃO
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Quote;