import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.css';
import { useLocation } from 'react-router';
import { AppContext } from "../../utils/ContextProvider";
import InputMask from 'react-input-mask';
import API from '../../utils/API';
import Swal from 'sweetalert2';

function PasswordRecover() {
    const { state } = useLocation();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const [email, setEmail] = useState(query.get("e"));
    // const navigate = useNavigate()
    const context = useContext(AppContext);
    const [submittionDisabled, setSubmittionDisabled] = useState(false)
    const [error, setError] = useState({ passwordError: false })
    const history = useHistory();
    const [form, setForm] = useState({
        password: "",
        confirmPassword: ""
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setError({ passwordError: false })
        setForm({ ...form, [name]: value });
    }

    function handleSubmit() {
        setSubmittionDisabled(true)
        Swal.fire({
            title: 'Processando recuperação de senha...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        var passValidation = validatePassword()
        if (passValidation !== '') {
            setError({ ...error, passwordError: passValidation })
            Swal.close()
            setSubmittionDisabled(false)
            return;
        }
        API.putPassword(API.cancelToken(), { password: form.password, token:query.get("t") }).then((response) => {
            Swal.fire({
                title: "Sucesso",
                text: "Senha alterada com sucesso!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#0F58D6",
            }).then(() => {
                history.push('/login');
            });
        }).catch((error) => {
            Swal.close()
            setSubmittionDisabled(false)
            Swal.fire({
                title: "Erro",
                text: "Não foi possível alterar sua senha. Entre em contato com o suporte.",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#0F58D6",
            });
        }
        )
    }

    const validatePassword = () => {
        if (form.password !== form.confirmPassword) {
            return 'Senhas não conferem!';
        }
        if(form.password.length === 0){
            return 'Preenchar sua senha!';
        }
        if(form.password.length < 6){
            return 'Senha deve ter no mínimo 6 caracteres!';
        }

        return '';
    }

    return (
        <div className="flex items-start justify-center gap-3 py-6 self-stretch w-full bg-[#EBF0F0] font-skf">

            <div className="flex justify-center items-center px-16 py-20 w-full text-base font-medium leading-5 max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col px-10 pt-10 pb-12 mt-4 mb-52 max-w-full bg-white rounded border border-solid border-neutral-300 w-[480px] max-md:px-5 max-md:mb-10">
                    <div className="text-3xl text-slate-800">Recuperação de Senha</div>
                    <div className="mt-8 text-sm leading-5 text-gray-500">Digite sua senha:</div>
                    <input className="px-4 py-3.5 mt-2 text-black whitespace-nowrap bg-white rounded-lg border border-solid border-neutral-300" placeholder="*****" type='password' value={form && form.password} name="password" onChange={handleChange}>
                    </input>
                    <div className="mt-8 text-sm leading-5 text-gray-500">Confirmar senha:</div>
                    <input className="px-4 py-3.5 mt-2 text-black whitespace-nowrap bg-white rounded-lg border border-solid border-neutral-300" placeholder="*****" type='password' value={form && form.confirmPassword} name="confirmPassword" onChange={handleChange}>
                    </input>
                    {error.passwordError && <div className='flex items-center gap-2 self-stretch mt-2'>
                        <img src={require('../../assets/images/warning-diamond.png')} alt='warning' />
                        <span className='flex-1 text-sm font-medium leading-4 text-[#273342]'>{error.passwordError}</span>
                    </div>}
                    <button className={`px-6 py-5 mt-8 text-lg leading-5 text-center text-white whitespace-nowrap rounded-lg ${submittionDisabled ? 'bg-slate-500' : 'bg-[#0F58D6]'} max-md:px-5`} onClick={handleSubmit} >
                        Recuperar Senha
                    </button>
                </div>
            </div>

        </div>
    )
}

export default PasswordRecover;