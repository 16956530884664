import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.css';
import { useLocation } from 'react-router';
import InputMask from 'react-input-mask';
import { AppContext } from "../../utils/ContextProvider";



function Home() {
    const { state } = useLocation();
    // const navigate = useNavigate()
    const [comapanyName, setCompantName] = useState("")
    const history = useHistory();
    const [phoneNumber, setPhoneNumber] = useState("")
    const [address, setAddress] = useState("")
    const [stage, setStage] = useState(3);
    const [password, setPassword] = useState("")
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [mask, setMask] = useState("+55 (99) 99999-9999")
    const [passwordStrength, setPasswordStrength] = useState(0)
    const [authCode, setAuthCode] = useState("")
    const [error, setError] = useState({ email: false, comapanyName: false, phoneNumber: false, address: false, password: false })
    const [submittionDisabled, setIsSubtionDisabled] = useState(false)
    const [submittionDisabled1, setIsSubtionDisabled1] = useState(false)
    const context = useContext(AppContext);
    const [form, setForm] = useState({
        comapanyName: "",
        phoneNumber: "",
        address: "",
        email: "",
        password: "",
        termsOfUse: false,
        policies: false,
        notifications: false
    })



    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }})




    // useEffect(() => {
    //     if (comapanyName && phoneNumber && address && email && password && termsOfUse && policies) {
    //         setIsSubmited(true)
    //     } else {
    //         setIsSubmited(false)
    //     }
    // }, [comapanyName, phoneNumber, address, email, password, termsOfUse, policies])


    const handlerSubmit = () => {
        var isValid = validationForm()
        if (!isValid) {
            return
        }
        return
    }


    const evaluatePasswordStrength = (password) => {
        // Criteria checks as before
        if (password.length > 40) {
            return 6
        }
        if (password.length < 5) {
            return 1
        }
        const metCriteria = [password.length >= 8, /\d/.test(password), /[A-Z]/.test(password), /[a-z]/.test(password), /[!@#$%^&*(),.?":{}|<>]/.test(password)].filter(Boolean).length;
        return metCriteria; // Returns a number between 0 and 5
    };

    const handleChange = (event) => {
        let { name, value } = event.target;

        let newForm = { ...form }
        if (name == "policies" || name == "termsOfUse" || name == "notifications") {
            newForm[name] = !newForm[name]
        }

        if (name == "email") {
            if (!validateEmail(value)) {
                setError({ ...error, email: "E-mail inválido" })
                setIsSubtionDisabled1(false)
            } else {
                newForm['email'] = value
                setIsSubtionDisabled1(true)
                setError({ ...error, email: null })
            }
        }

        console.log(newForm)
        setForm(newForm)

    }
    const handlePasswordChange = (event) => {
        const { value } = event.target;
        setPassword(value);
        if (value === "") {
            setPasswordStrength(0);
            return;
        }
        setPasswordStrength(evaluatePasswordStrength(value));
    };

    const moveStage = () => {
        setStage(stage + 1)
    }


    const validationForm = () => {
        var auxError = { email: false, comapanyName: false, phoneNumber: false, address: false, password: false }
        var isValid = true
        if (!comapanyName) {
            auxError.comapanyName = "Campo Obrigatório"
            isValid = false
        }
        if (!validatePhone(phoneNumber)) {
            auxError.phoneNumber = "Telefone inválido"
            isValid = false
        }
        if (!address) {
            auxError.address = "Campo Obrigatório"
            isValid = false
        } if (!(passwordStrength === 4 || passwordStrength === 5 || passwordStrength === 3)) {
            auxError.password = "Senha Fraca"
            isValid = false
        }
        if (passwordStrength === 6) {
            auxError.password = "Senha deve conter menos de 40 caracteres."
            isValid = false
        }
        setError(auxError)
        return isValid
    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }

    const validatePhone = (phone) => {
        phone = `${phone}`.replace(/\D/g, '');
        if (phone.length === 13 || phone.length === 12) {
            return true
        } else {
            return false
        }
    };

    const handleUpdatePhone = (event) => {
        const { value } = event.target;
        var phone = `${value}`.replace(/\D/g, '');
        setPhoneNumber(phone)
        if (!validatePhone(phone)) {
            // setError(true)
        } else {
            // setError(false)
        }
    }

    return (
        <div className="flex flex-col bg-white">
            <div className="flex flex-col justify-center w-full text-white bg-white max-md:max-w-full">
                <div className="flex relative flex-col px-12 py-20 w-full min-h-[458px] max-md:px-5 max-md:max-w-full">
                    <img
                        loading="lazy"
                        src = {require("../../assets/images/hero1.png")}
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                    <div className="relative mt-14 text-6xl font-light leading-[72.24px] max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                        Seja Bem Vindo!
                    </div>
                    <div className="relative mt-6 text-base leading-6 max-w-[600px] max-md:max-w-full">
                        A nova plataforma SKF para solicitação de orçamentos foi
                        desenvolvida pensando em você.
                    </div>
                    <Link to={"./quote"} className="relative self-start px-6 pt-4 pb-5 mt-10 text-lg font-medium leading-5 text-center text-blue-700 bg-white rounded-lg max-md:px-5">
                        Solicitar orçamento
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Home;